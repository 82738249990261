<template>
  <div>
    <b-row>
      <b-col md="6">
        <!-- Name -->
        <validation-provider
          #default="validationContext"
          name="Name"
          rules="required|min:3|max:200"
        >
          <b-form-group
            label="Name"
            label-for="name"
          >
            <b-form-input
              id="name"
              v-model="inpoDetails.name"
              autofocus
              :state="getValidationState(validationContext)"
              trim
              placeholder="Name"
            />
            <b-form-invalid-feedback>
              {{ validationContext.errors[0] }}
            </b-form-invalid-feedback>
          </b-form-group>
        </validation-provider>
      </b-col>

      <b-col md="6">
        <!-- Contact Name -->
        <validation-provider
          #default="validationContext"
          name="Contact Name"
          rules="min:3|max:200"
        >
          <b-form-group
            label="Contact Person"
            label-for="contact name"
          >
            <b-form-input
              id="contact name"
              v-model="inpoDetails.contact_name"
              :state="getValidationState(validationContext)"
              trim
              placeholder="Contact Person"
            />
            <b-form-invalid-feedback>
              {{ validationContext.errors[0] }}
            </b-form-invalid-feedback>
          </b-form-group>
        </validation-provider>
      </b-col>

      <!-- Mobile Number -->
      <b-col md="6">
        <validation-provider
          #default="validationContext"
          name="Mobile Number"
          rules="min:6|max:14|phone"
        >
          <b-form-group
            label="Mobile Number"
            label-for="mobile-number"
          >
            <b-form-input
              id="mobile-number"
              v-model="inpoDetails.phone_number"
              :state="getValidationState(validationContext)"
              trim
              type="tel"
            />

            <b-form-invalid-feedback>
              {{ validationContext.errors[0] }}
            </b-form-invalid-feedback>
          </b-form-group>
        </validation-provider>
      </b-col>

      <!-- Email -->
      <b-col md="6">
        <validation-provider
          #default="validationContext"
          name="Email"
          rules="email"
        >
          <b-form-group
            label="Email"
            label-for="email"
          >
            <b-form-input
              id="email"
              v-model="inpoDetails.email"
              :state="getValidationState(validationContext)"
              trim
            />

            <b-form-invalid-feedback>
              {{ validationContext.errors[0] }}
            </b-form-invalid-feedback>
          </b-form-group>
        </validation-provider>
      </b-col>

      <!-- About -->
      <b-col md="6">
        <validation-provider
          #default="validationContext"
          name="About"
          rules="max:255"
        >
          <b-form-group
            label="About"
            label-for="about"
          >
            <b-form-textarea
              id="about"
              v-model="inpoDetails.about"
              :state="getValidationState(validationContext)"
              placeholder="About"
            />
            <b-form-invalid-feedback>
              {{ validationContext.errors[0] }}
            </b-form-invalid-feedback>
          </b-form-group>
        </validation-provider>
      </b-col>
      <b-col>
        <b-form-group
          label="Has Prayers"
          label-for="has_prayers"
        >
          <b-checkbox
            id="has_prayers"
            v-model="inpoDetails.has_prayers"
            switch
          />
        </b-form-group></b-col>
    </b-row>
    <b-form-row>
      <!-- Classification -->
      <b-col md="3">
        <validation-provider
          #default="validationContext"
          name="Classification"
        >
          <b-form-group
            label="Classification"
            label-for="classification"
            :state="getValidationState(validationContext)"
          >
            <v-select
              v-model="inpoDetails.classification_id"
              label="name"
              :reduce="tag => tag.id"
              :options="classificationsOptions"
              multiple
              :close-on-select="false"
              disabled
            />
            <b-form-invalid-feedback>
              {{ validationContext.errors[0] }}
            </b-form-invalid-feedback>
          </b-form-group>
        </validation-provider>
      </b-col>

      <!-- subClassification -->
      <b-col md="3">
        <validation-provider
          #default="validationContext"
          name="subClassification"
          rules="required"
        >
          <b-form-group
            label="Sub Classification"
            label-for="sub-classification"
          >
            <v-select
              v-model="activeSelectedSubClass"
              label="name"
              :reduce="tag => tag.id"
              :options="subClassifications"
              multiple
              :close-on-select="false"
            />
            <b-form-invalid-feedback :state="getValidationState(validationContext)">
              {{ validationContext.errors[0] }}
            </b-form-invalid-feedback>
          </b-form-group>
        </validation-provider>
      </b-col>
      <!-- Donation Category -->
      <b-col md="3">
        <validation-provider
          #default="validationContext"
          name="Donation Category"
        >
          <b-form-group
            label="Donation Category"
            label-for="donation-category"
          >
            <v-select
              id="donation-category"
              v-model="activeSelectedDonationCategories"
              multiple
              label="category_name"
              :reduce="d => d.id"
              :options="donationCategories"
              :close-on-select="false"
            />
            <b-form-invalid-feedback :state="getValidationState(validationContext)">
              {{ validationContext.errors[0] }}
            </b-form-invalid-feedback>
          </b-form-group>
        </validation-provider>
      </b-col>
      <b-col md="3">
        <validation-provider
          #default="validationContext"
          name="Language"
        >
          <b-form-group
            label="Language"
            label-for="language"
          >
            <v-select
              id="language"
              v-model="activeSelectedLanguages"
              multiple
              :reduce="lang => lang.id"
              label="language_name"
              :options="languages"
              :close-on-select="false"
            />
            <b-form-invalid-feedback :state="getValidationState(validationContext)">
              {{ validationContext.errors[0] }}
            </b-form-invalid-feedback>
          </b-form-group>
        </validation-provider>
      </b-col>

      <b-col md="6">
        <b-row>
          <b-col
            md="6"
            lg="8"
          >
            <facilities-select
              ref="facilities-select"
              :facilities.sync="inpoDetails.facilities"
              :category="initialFacility[entityType].category"
            />
          </b-col>
          <b-col class="mt-2">
            <b-link
              v-b-modal.facility-add-modal
              variant="primary"
            >
              <span class="text-nowrap">Add Facility</span>
            </b-link>
          </b-col>
        </b-row>
      </b-col>

      <b-col md="6">
        <b-row>
          <b-col
            md="6"
            lg="8"
          >
            <services-select
              ref="services-select"
              :services.sync="inpoDetails.services"
              :category="initialService[entityType].category"
            />
          </b-col>
          <b-col class="mt-2">
            <b-link
              v-b-modal.service-add-modal
              variant="primary"
            >
              <span class="text-nowrap">Add Service</span>
            </b-link>
          </b-col>
        </b-row>
      </b-col>

      <b-col md="6">
        <products-select
          :products.sync="inpoDetails.products"
          category="islamic organization"
        />
      </b-col>

      <b-col md="6">
        <programs-select
          :programs.sync="inpoDetails.programs"
          category="islamic organization"
        />
      </b-col>

      <b-col md="6">
        <entity-select
          label="Entity Parent"
          :entity.sync="inpoDetails.parent"
          :filter="{classification: 'Organization', withoutParent: true}"
        />

        <b-checkbox
          v-model="inpoDetails.is_main_branch"
          class="my-1"
        >
          Main Branch
        </b-checkbox>
      </b-col>

      <!-- Modals -->
      <div>
        <!-- Add Facility -->
        <b-modal
          id="facility-add-modal"
          centered
          title="Add Facility"
          hide-footer
          no-close-on-backdrop
          size="lg"
          @hidden="facility=initialFacility[entityType]"
        >
          <facility-form
            :facility="facility"
            :submit="addFacility"
            :is-category-enabled="false"
            :is-status-enabled="false"
          />
        </b-modal>

        <!-- Add Service -->
        <b-modal
          id="service-add-modal"
          centered
          title="Add Service"
          hide-footer
          no-close-on-backdrop
          size="lg"
          @hidden="service=initialService[entityType]"
        >
          <service-form
            :service="service"
            :submit="addService"
            :is-category-enabled="false"
            :is-status-enabled="false"
          />
        </b-modal>
      </div>
    </b-form-row>
    <b-form-row>
      <!-- Location -->
      <b-col md="3">
        <validation-provider
          #default="validationContext"
          name="Location"
        >
          <b-form-group
            label="Location"
            label-for="location"
          >
            <input
              id="location"
              ref="locationRef"
              v-b-tooltip.hover
              title="This input is only for searching address"
              :state="getValidationState(validationContext)"
              trim
              placeholder="Enter address to search"
              class="form-control"
            >
            <b-form-invalid-feedback>
              {{ validationContext.errors[0] }}
            </b-form-invalid-feedback>
          </b-form-group>
        </validation-provider>
      </b-col>

      <!-- Street -->
      <b-col md="3">
        <validation-provider
          #default="validationContext"
          name="street"
        >
          <b-form-group
            label="Street"
            label-for="street"
          >
            <b-form-input
              id="street"
              v-model="inpoDetails.address[0].street"
              :state="getValidationState(validationContext)"
              trim
              placeholder="Street"
            />
            <b-form-invalid-feedback>
              {{ validationContext.errors[0] }}
            </b-form-invalid-feedback>
          </b-form-group>
        </validation-provider>
      </b-col>

      <!-- City -->
      <b-col md="3">
        <validation-provider
          #default="validationContext"
          name="City"
        >
          <b-form-group
            label="City"
            label-for="city"
          >
            <b-form-input
              id="city"
              v-model="inpoDetails.address[0].city"
              :state="getValidationState(validationContext)"
              trim
              placeholder="City"
            />
            <b-form-invalid-feedback>
              {{ validationContext.errors[0] }}
            </b-form-invalid-feedback>
          </b-form-group>
        </validation-provider>
      </b-col>
      <!-- State -->
      <b-col md="3">
        <validation-provider
          #default="validationContext"
          name="State"
        >
          <b-form-group
            label="Province / State"
            label-for="state"
          >
            <b-form-input
              id="state"
              v-model="inpoDetails.address[0].state"
              :state="getValidationState(validationContext)"
              trim
              placeholder="State"
            />
            <b-form-invalid-feedback>
              {{ validationContext.errors[0] }}
            </b-form-invalid-feedback>
          </b-form-group>
        </validation-provider>
      </b-col>
    </b-form-row>
    <b-form-row>
      <!-- Country -->
      <b-col md="3">
        <validation-provider
          #default="validationContext"
          name="Country"
        >
          <b-form-group
            label="Country"
            label-for="country"
          >
            <b-form-input
              id="country"
              v-model="inpoDetails.address[0].country"
              :state="getValidationState(validationContext)"
              trim
              placeholder="Country"
            />
            <b-form-invalid-feedback>
              {{ validationContext.errors[0] }}
            </b-form-invalid-feedback>
          </b-form-group>
        </validation-provider>
      </b-col>

      <!-- Postal Code -->
      <b-col md="3">
        <validation-provider
          #default="validationContext"
          name="PostalCode"
        >
          <b-form-group
            label="Zip / Postal Code"
            label-for="postalCode"
          >
            <b-form-input
              id="postalCode"
              v-model="inpoDetails.address[0].postal_code"
              :state="getValidationState(validationContext)"
              trim
              placeholder="Zip / Postal Code"
            />
            <b-form-invalid-feedback>
              {{ validationContext.errors[0] }}
            </b-form-invalid-feedback>
          </b-form-group>
        </validation-provider>
      </b-col>

      <b-col md="3">
        <validation-provider
          #default="validationContext"
          name="latitude"
        >
          <b-form-group
            label="Latitude"
            label-for="latitude"
          >
            <b-form-input
              id="latitude"
              v-model="inpoDetails.location.coordinates[0]"
              :state="getValidationState(validationContext)"
              trim
              placeholder="Latitude"
            />
            <b-form-invalid-feedback>
              {{ validationContext.errors[0] }}
            </b-form-invalid-feedback>
          </b-form-group>
        </validation-provider>
      </b-col>

      <b-col md="3">
        <validation-provider
          #default="validationContext"
          name="longitude"
        >
          <b-form-group
            label="Longitude"
            label-for="longitude"
          >
            <b-form-input
              id="longitude"
              v-model="inpoDetails.location.coordinates[1]"
              :state="getValidationState(validationContext)"
              trim
              step="any"
              placeholder="Longitude"
            />
            <b-form-invalid-feedback>
              {{ validationContext.errors[0] }}
            </b-form-invalid-feedback>
          </b-form-group>
        </validation-provider>
      </b-col>
    </b-form-row>

    <b-form-row>
      <b-col md="6">
        <b-form-row>
          <b-col cols="12">
            <upload-image
              id="inpo-logo"
              label="Logo"
              rules="max-dimension:1200,1200|image-aspect-ratio:1,1"
              :images.sync="inpoDetails.logo"
            />
          </b-col>

          <b-col cols="12">
            <images-base-64-preview
              file-id="inpo-logo"
              :images.sync="inpoDetails.logo"
            />
          </b-col>
        </b-form-row>
      </b-col>

      <b-col md="6">
        <b-form-row>
          <b-col cols="12">
            <upload-image
              id="inpo-cover"
              label="Cover"
              rules="image-aspect-ratio:2,1"
              :images.sync="inpoDetails.cover"
            />
          </b-col>

          <b-col cols="12">
            <images-base-64-preview
              file-id="inpo-cover"
              :images.sync="inpoDetails.cover"
            />
          </b-col>
        </b-form-row>
      </b-col>
    </b-form-row>
  </div>
</template>

<script>
import vSelect from 'vue-select'
import formValidation from '@core/comp-functions/forms/form-validation'
import locationAutoComplete from '@/common/compositions/common/locationAutoComplete'
import activeSelectedValues from '@/common/compositions/common/activeSelectedValues'
import domainItemApi from '@/common/compositions/DomainItems/domainItemApi'
import ImagesBase64Preview from '../../common/FormInputs/ImagesBase64Preview.vue'
import UploadImage from '../../common/FormInputs/UploadImage.vue'
import ServiceForm from '../../GeneralSettings/services/ServiceForm.vue'
import FacilityForm from '../../GeneralSettings/facilities/FacilityForm.vue'
import ServicesSelect from '../../GeneralSettings/services/ServicesSelect.vue'
import FacilitiesSelect from '../../GeneralSettings/facilities/FacilitiesSelect.vue'
import ProgramsSelect from '../../GeneralSettings/programs/ProgramsSelect.vue'
import ProductsSelect from '../../GeneralSettings/products/ProductsSelect.vue'
import EntitySelect from '../../Entities/EntitySelect.vue'

export default {
  components: {
    vSelect,
    UploadImage,
    ImagesBase64Preview,
    ServiceForm,
    FacilityForm,
    ServicesSelect,
    FacilitiesSelect,
    ProgramsSelect,
    ProductsSelect,
    EntitySelect,
  },
  props: {
    inpoDetails: {
      type: Object,
      default: () => {},
    },
    donationCategories: {
      type: Array,
      default: () => [],
    },
    classificationsOptions: {
      type: Array,
      default: () => [],
    },
    subClassifications: {
      type: Array,
      default: () => [],
    },
    languages: { type: Array, default: () => [] },
  },
  computed: {
    entityType() {
      return 'islamicOrganization'
    },
    activeSelectedSubClass: {
      get() {
        return this.getActiveSelectedValues(this.subClassifications, this.inpoDetails.subClassifications_id)
      },
      set(value) {
        this.inpoDetails.subClassifications_id = value
      },
    },
    activeSelectedLanguages: {
      get() {
        return this.getActiveSelectedValues(this.languages, this.inpoDetails.languages_id)
      },
      set(value) {
        this.inpoDetails.languages_id = value
      },
    },
    activeSelectedDonationCategories: {
      get() {
        return this.getActiveSelectedValues(this.donationCategories, this.inpoDetails.donation_categories_id)
      },
      set(value) {
        this.inpoDetails.donation_categories_id = value
      },
    },
  },
  mounted() {
    this.initAutocomplete(this.inpoDetails.address[0], this.inpoDetails.location)
  },
  setup() {
    const {
      getValidationState,
    } = formValidation()

    const {
      initAutocomplete,
      locationRef,
    } = locationAutoComplete()

    const { getActiveSelectedValues } = activeSelectedValues()

    const {
      initialItem: initialFacility, item: facility, addItemRequest: addFacilityRequest, resetItem: resetFacility,
    } = domainItemApi('entities', '/internalops/facility')

    const {
      initialItem: initialService, item: service, addItemRequest: addServiceRequest, resetItem: resetService,
    } = domainItemApi('entities', '/internalops/service')

    resetFacility('islamicOrganization')
    resetService('islamicOrganization')

    return {
      facility,
      addFacilityRequest,
      addServiceRequest,
      service,
      getValidationState,
      initAutocomplete,
      locationRef,
      getActiveSelectedValues,
      initialFacility,
      initialService,
    }
  },
  methods: {
    addService() {
      return this.addServiceRequest(this.entityType).then(() => {
        this.$bvModal.hide('service-add-modal')
        this.$refs['services-select'].getServices()
      })
    },
    addFacility() {
      return this.addFacilityRequest(this.entityType).then(() => {
        this.$bvModal.hide('facility-add-modal')
        this.$refs['facilities-select'].getFacilities()
      })
    },
  },
}
</script>

<style lang="scss">

</style>
