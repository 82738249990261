<template>
  <div>
    <domain-items-select
      id="programs-select"
      label="Select Program"
      :items.sync="programsProxy"
      :category="category"
      :get-items-request="getItemsRequest"
    />
  </div>
</template>
<script>
import domainItemApi from '@/common/compositions/DomainItems/domainItemApi'
import DomainItemsSelect from '../domain-items/DomainItemsSelect.vue'

export default {
  name: 'ProgramsSelect',
  components: { DomainItemsSelect },
  props: {
    programs: { type: Array, default: () => [] },
    category: { type: String, default: 'business' },
  },
  computed: {
    programsProxy: {
      get() {
        return this.programs
      },
      set(value) {
        this.$emit('update:programs', value)
      },
    },
  },
  setup() {
    const { getItemsRequest } = domainItemApi('entities', '/internalops/program')
    return { getItemsRequest }
  },
}
</script>
<style lang="">

</style>
