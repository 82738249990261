<template>
  <div v-if="Object.keys(inpoData).length !== 0">
    <validation-observer
      v-slot="{ handleSubmit }"
    >
      <!-- BODY -->
      <form-wizard
        color="#00A5AF"
        :title="null"
        :subtitle="null"
        shape="square"
        finish-button-text="Submit"
        back-button-text="Previous"
        class="mb-3 p-3"
        @on-complete="handleSubmit(updateInpo)"
      >
        <tab-content
          :before-change="()=>{
            if($refs.inpoDetails.$data.flags.invalid)
              $refs.inpoDetails.handleSubmit();
            return !$refs.inpoDetails.$data.flags.invalid
          }"
          title="Organization Details"
        >
          <validation-observer ref="inpoDetails">
            <inpo-details
              :inpo-details="inpoData"
              :options="options"
              :languages="activeLanguages"
              :donation-categories="activeDonationCategories"
              :classifications-options="activeClassifications"
              :sub-classifications="subClass"
            />
          </validation-observer>
          <div class="btn-back">
            <back />
          </div>
        </tab-content>

        <tab-content
          :before-change="()=>{
            if($refs.inpoSocial.$data.flags.invalid)
              $refs.inpoSocial.handleSubmit();
            return !$refs.inpoSocial.$data.flags.invalid
          }"
          title="Social"
        >
          <validation-observer
            ref="inpoSocial"
          >
            <inpo-social
              :inpo-details="inpoData"
              :tag-options="activeTags.filter(tag=>tag.category === 'islamic organization')"
            />
          </validation-observer>
        </tab-content>

        <tab-content
          :before-change="()=>{
            if($refs.paymentDetails.$data.flags.invalid)
              $refs.paymentDetails.handleSubmit();
            return !$refs.paymentDetails.$data.flags.invalid
          }"
          title="Payment Details"
        >
          <validation-observer ref="paymentDetails">
            <payment-details
              :payment-details-data="inpoData"
            />

            <b-form-row>
              <b-col md="6">
                <b-form-row>
                  <b-col cols="12">
                    <upload-image
                      id="inpo-signature"
                      label="Signature"
                      :images.sync="inpoData.sign_path"
                    />
                  </b-col>
                  <b-col cols="12">
                    <images-base-64-preview
                      file-id="inpo-signature"
                      :images.sync="inpoData.sign_path"
                    />
                  </b-col>
                </b-form-row>
              </b-col>
            </b-form-row>
          </validation-observer>
        </tab-content>

        <upload-progress :progress="progress" />
      </form-wizard>
    </validation-observer>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import 'vue-form-wizard/dist/vue-form-wizard.min.css'
import { FormWizard, TabContent } from 'vue-form-wizard'
import formValidation from '@core/comp-functions/forms/form-validation'
import Ripple from 'vue-ripple-directive'

import inpoDetails from '@/common/components/Inpos/Edit/InpoDetails.vue'
import InpoSocial from '@/common/components/Inpos/Edit/InpoSocial.vue'
import paymentDetails from '@/common/components/Inpos/Edit/PaymentDetails.vue'
import UploadProgress from '@/common/components/common/FormInputs/UploadProgress.vue'
import UploadImage from '@/common/components/common/FormInputs/UploadImage.vue'

import getInpo from '@/common/compositions/Inpos/getInpo'
import getOrganizationDetails from '@/common/compositions/GeneralSettings/getGeneralSettings'
import handleAlerts from '@/common/compositions/common/handleAlerts'
import uploadPercentage from '@/common/compositions/common/uploadPercentage'
import Back from '@/common/components/common/FormInputs/Back.vue'
import ImagesBase64Preview from '@/common/components/common/FormInputs/ImagesBase64Preview.vue'

export default {
  name: 'EditInpo',
  components: {
    FormWizard,
    TabContent,
    inpoDetails,
    InpoSocial,
    paymentDetails,
    UploadProgress,
    UploadImage,
    Back,
    ImagesBase64Preview,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      options: ['English', 'Arabic', 'German', 'other'],
      autocomplete: null,
      place: null,
      classifications: [
        {
          id: 1,
          name: 'Organization',
        },
      ],
      newInpoData: null,
    }
  },
  setup() {
    const { progress, calculateUploadPercentage } = uploadPercentage()

    const {
      refFormObserver,
      getValidationState,
      resetForm,
    } = formValidation()

    const {
      inpoData, getInpoData,
    } = getInpo()

    const {

      successfulOperationAlert,
    } = handleAlerts()

    const {
      activeDonationCategories,
      activeLanguages,
      activeTags,
      activeClassifications,
    } = getOrganizationDetails()

    return {
      refFormObserver,
      getValidationState,
      resetForm,
      activeDonationCategories,
      activeLanguages,
      activeTags,
      activeClassifications,
      inpoData,
      getInpoData,

      successfulOperationAlert,
      progress,
      calculateUploadPercentage,
    }
  },
  computed: {
    ...mapGetters({ subClass: 'generalSettings/getActiveSubClassifications' }),
  },
  created() {
    this.$store.dispatch('generalSettings/getClassSubClassificationRequest', this.classifications[0].id)
    this.getInpoData()
  },
  methods: {
    updateInpo() {
      this.setNewInpoData()
      this.$entities.post(`internalops/entity/${this.$router.currentRoute.params.id}?_method=PUT`, this.newInpoData, {
        onUploadProgress: progressEvent => { this.calculateUploadPercentage(progressEvent) },
      })
        .then(() => {
          this.successfulOperationAlert('Updated Successfully')
          if (this.$can('show', 'global-EntitiesController')) {
            this.$router.push({ name: 'inpos-list' })
          } else {
            this.$router.back()
          }
        }).catch(err => {
          const text = err.response.data.errors.name?.[0]
          if (text.includes('Entity with same')) {
            const numberRegex = /\d+/

            const match = text.match(numberRegex)
            const extractedNumber = match ? parseInt(match[0], 10) : null
            this.$router.push({ name: 'admin-inpo-edit', params: { id: extractedNumber } }).then(() => {
              this.successfulOperationAlert(`You are redirected to entity ${this.inpoData.name}`)
            })
          }
        })
    },
    setNewInpoData() {
      this.newInpoData = {
        name: this.inpoData.name,
        parentID: this.inpoData.parent?.id,
        lat: this.inpoData.location.coordinates[1],
        lng: this.inpoData.location.coordinates[0],
        logo: this.inpoData.logo[0],
        cover: this.inpoData.cover[0],
        about: this.inpoData.about,
        phone_number: this.inpoData.phone_number,
        email: this.inpoData.email,
        social_facebook: this.inpoData.social_facebook,
        social_twitter: this.inpoData.social_twitter,
        social_instagram: this.inpoData.social_instagram,
        social_youtube: this.inpoData.social_youtube,
        website: this.inpoData.website,
        classifications: [1],
        street: this.inpoData.address[0].street,
        city: this.inpoData.address[0].city,
        state: this.inpoData.address[0].state,
        country: this.inpoData.address[0].country,
        postal_code: this.inpoData.address[0].postal_code,
        tag_description: null,
        subclassifications: this.inpoData.subClassifications_id,

        language_id: this.inpoData.languages_id,
        tag_id: this.inpoData.tags_id,
        donation_cats_id: this.inpoData.donation_categories_id,

        payment_type: this.inpoData.paymentdetails.payment_type,
        business_type: this.inpoData.paymentdetails.business_type,
        business_name: this.inpoData.paymentdetails.business_name,
        legal_name: this.inpoData.paymentdetails.legal_name,
        account_holder_name: this.inpoData.paymentdetails.account_holder_name,
        account_holder_position: this.inpoData.paymentdetails.account_holder_position,
        account_holder_dofb: this.inpoData.paymentdetails.account_holder_dofb,
        transit_number: this.inpoData.paymentdetails.transit_number,
        institution_number: this.inpoData.paymentdetails.institution_number,
        account_number: this.inpoData.paymentdetails.account_number,
        phoneNum: this.inpoData.paymentdetails.phoneNum,
        charitable_number: this.inpoData.paymentdetails.charitable_number,
        sign_path: this.inpoData.sign_path[0],
        fax_number: this.inpoData.fax_number,
        contact_name: this.inpoData.contact_name,
        id: this.$router.currentRoute.params.id,
        street_tax: this.inpoData.taxReceiptAddress.street,
        city_tax: this.inpoData.taxReceiptAddress.city,
        state_tax: this.inpoData.taxReceiptAddress.state,
        country_tax: this.inpoData.taxReceiptAddress.country,
        postal_code_tax: this.inpoData.taxReceiptAddress.postal_code,
        lat_tax: this.inpoData.taxReceiptAddress.lat,
        lng_tax: this.inpoData.taxReceiptAddress.lng,
        facilitiesIDs: this.inpoData.facilities?.map(facility => facility.id),
        servicesIDs: this.inpoData.services?.map(service => service.id),
        programsIDs: this.inpoData.programs?.map(program => program.id),
        productsIDs: this.inpoData.products?.map(product => product.id),
        has_prayers: !!this.inpoData.has_prayers,
      }
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-wizard.scss';
@import '@core/scss/vue/libs/vue-select.scss';
</style>
